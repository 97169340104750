import { Assets } from './Assets'

const appdev = [
    {
        title: 'Lollet',
        description: 'Lollet is a digital loyalty platform that simplifies collecting loyalty stamps and claiming rewards. Users can easily earn and redeem points from various businesses, all in one convenient app.',
        tags: ['Flutter', 'Firebase', 'Cloud Functions', 'Firestore', 'Google Maps API'],
        icon: Assets.lolletIcon,
        type: 'appdev',
        link: 'https://lollet.kerkarcreations.com',
        image: 'https://media.giphy.com/media/BM0m0OEoJWPe82Z4D8/giphy.gif',
        longMedia: true,
    },
    {
        title: 'Deals On Bills',
        description: 'Deals on Bills connects businesses and users seamlessly. Businesses can showcase their exclusive offers and locations, while users easily find and save deals nearby.',
        tags: ['Flutter', 'Django', 'PostgreSQL/PostGIS', 'Google Maps API', 'AWS EC2', 'AWS S3', 'AWS RDS'],
        icon: Assets.dealsonbillsIcon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.halo.dealsonbillsmerchant',
        image: 'https://media.giphy.com/media/tQ45jd3q3RSoH65g4c/giphy.gif',
        longMedia: true,
    },
    {
        title: 'Logs',
        description: 'Logs is an all-in-one productivity app. It combines task management, financial tracking, and habit building, offering seamless integration to help you achieve your goals. Stay organized with the to-do list, manage finances efficiently, and track habits for a healthier, more productive life.',
        tags: ['Flutter', 'Firebase', 'Firestore'],
        icon: Assets.logsIcon,
        type: 'appdev',
        link: 'https://logs.karunshrestha.com.np',
        image: 'https://media.giphy.com/media/xCeVQZDR2D1TjiibE6/giphy.gif',
        longMedia: true,
    },
    {
        title: 'Count',
        description: 'Count is a versatile day tracking app. The app supports both private and public counts, and users can follow and countdown to events created by others. Users can make countdown fun with background images or emojis and share them as stickers on Snapchat.',
        tags: ['Flutter', 'Django', 'PostgreSQL/PostGIS', 'SnapKit', 'AWS Lightsail', 'Docker'],
        icon: Assets.countIcon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.halo.count',
        image: 'https://media.giphy.com/media/ES2keUMnMhz4Mywlsc/giphy.gif',
        longMedia: true,
    },
    {
        title: 'KEC Daily Routine',
        description: 'KEC Daily Routine is made for the students of Kathmandu Engineering College to view their daily routines. It is a platform for class routines where class representatives uploads routines for their classes and students of the class can follow the routine.',
        tags: ['Android SDK', 'Firebase'],
        icon: Assets.kecIcon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.halo.kecroutine',
        image: 'https://media.giphy.com/media/vbnqIaJVz0bM2rHExb/giphy.gif'
    },
    {
        title: 'Opti Care',
        description: 'Opti Care serves as an effective eye break reminder, following the 20-20-20 rule, and features a customizable blue light filter. Helps users combat eye strain, headaches, and sleep disruption by effortlessly integrating Opti Care into your digital routine.',
        tags: ['Android SDK'],
        icon: Assets.opticareIcon,
        type: 'appdev',
        image: 'https://media.giphy.com/media/UgBtzi4m99OUgbQz0d/giphy.gif'
    },
    {
        title: 'EV Nepal',
        description: 'Locate nearby EV charging stations and plan routes by checking charging points between destinations.',
        tags: ['Flutter', 'Django', 'PostgreSQL/PostGIS', 'AWS Lightsail', 'Docker'],
        icon: Assets.evnepalIcon,
        type: 'appdev'
    },
    {
        title: 'Noti Notes',
        description: 'Noti Notes simplifies reminders with its unique sticky note/notification combo. Notes are conveniently displayed in the notification bar, easily accessible by swiping down or unlocking your phone.',
        tags: ['Android SDK'],
        icon: Assets.notinotesIcon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.halo.notinotes',
        image: 'https://media.giphy.com/media/iepuu0HBrREKzWPatM/giphy.gif'
    },
    {
        title: 'Amoled Clock',
        description: 'I developed a simple app to repurpose my old phone as a sleek, energy-efficient clock, giving a new life to my device. I also added home controls to manage the lights in my room, making it a functional and stylish desk companion.',
        tags: ['Android SDK', 'Firebase'],
        icon: Assets.amoledIcon,
        type: 'appdev',
        image: 'https://media.giphy.com/media/UkY67c5qZsHb5q7Dwd/giphy.gif'
    },
    {
        title: 'LDC325A1',
        description: 'Leo District Council 325 A1 directory app. Explore members of the district, view their contact information, and connect with them on social media.',
        tags: ['Flutter', 'Firebase'],
        icon: Assets.ldc325a1Icon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.leonepal.LDC_325A1'
    },
    {
        title: 'NNZFSC',
        description: 'App for the community of Nepal New Zealand Friendship Society of Canterbury. The app allows users to view and register for events, and join the community.',
        tags: ['Flutter'],
        icon: Assets.nnzfsc,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.nnzfsc.app'
    },
    {
        title: 'MP4 Video Player',
        description: 'Custom video player using ExoPlayer. Inspired from the design of stock video player apps available on Sony\'s phones.',
        tags: ['Android SDK',],
        icon: Assets.mp4videoplayerIcon,
        type: 'appdev',
        link: 'https://play.google.com/store/apps/details?id=com.halo.videoplayer'
    }
];


const webdev = [
    {
        title: 'Links',
        description: 'A one-stop destination for all my social platforms. The design takes inspiration from the Apple Watch OS bubble UI, offering a fun experience to connect with me on Facebook, Instagram, and beyond.',
        tags: ['HTML', 'CSS', 'React JS'],
        type: 'webdev',
        image: 'https://media.giphy.com/media/eMIhE6uOAaO0yuJoOX/giphy.gif',
        link: 'https://links.karunshrestha.com.np/',
    },
    {
        title: 'NNZFSC',
        description: 'I was responsible for the backend development of the website for the Nepal New Zealand Friendship Society of Canterbury. The website allows users to view and register for events, join the community and learn about the community and it\'s members.',
        tags: ['Django', 'MySQL'],
        type: 'webdev',
        image: 'https://i.giphy.com/media/D4xPjk1uLeWYHEM3Lc/giphy.gif',
        link: 'https://www.nnzfsc.co.nz/'
    },
    {
        title: 'AR Business Card',
        description: 'A WebXR project using A-Frame to create an interactive business card, activated when the user scans the QR code on the card; A virtual version of the card is displayed, and users can interact with the card by tapping on the buttons to view my social media profiles and contact information.',
        tags: ['HTML', 'CSS', 'Javascript', 'A-Frame'],
        type: 'webdev',
        image: 'https://media.giphy.com/media/Hij41kFQzhfzgDSbNM/giphy.gif',
        link: 'https://bcard.kerkarcreations.com/karun/'
    },
];

const arvr = [
    {
        title: 'AR Football',
        description: 'A two-player lens game where one player aims to score by shooting the ball into the goal post, while the other player strategically maneuvers the goalkeeper to block incoming shots. The game utilizes Lens Studio\'s Connected Lens feature, allowing players to compete with friends in real-time.',
        tags: ['Lens Studio', 'Meta Spark Studio', 'Javascript', 'Illustrator'],
        icon: Assets.footballIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/nH6YJ8Npa7s7O2o2FQ/giphy.gif'
    },
    {
        title: 'Guitar Tutor',
        description: 'Effortlessly visualize chord finger placements using image marker tracking. The filter detects the marker and displays the chord on the guitar neck. Users can also switch between chords by tapping on the screen or by saying the name of the chord.',
        tags: ['Lens Studio', 'Javascript', 'Illustrator'],
        icon: Assets.guitarIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/3o1cKhxhDq4oAgVKVE/giphy-downsized-large.gif'
    },
    {
        title: 'Slice Shuffle',
        description: 'A pizza game where players arrange slices to create the perfect 8-slice pizza. Developed in collaboration with a local brand, the game utilizes a unique marker – the pizza box itself. Simply scan the box to turn it into the gaming platform and enjoy the interactive pizza-making experience!',
        tags: ['Lens Studio', 'Meta Spark Studio', 'Blender', 'Javascript', 'Photoshop'],
        icon: Assets.sliceIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/dLcGsZbldUR2ltRFHs/giphy-downsized-large.gif'
    },
    {
        title: 'Word Play',
        description: 'Wordplay is a fun and interactive Snapchat Lens where you guess the word based on a given hint. Using AR, letters float around you, and your task is to tap on the correct letters to form the word. It’s a great way to challenge your vocabulary while enjoying a unique, immersive experience!',
        tags: ['Lens Studio', 'Javascript', 'Illustrator'],
        icon: Assets.wordPlayIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/N5Y8U7XwgMHLdkaytj/giphy.gif'
    },
    {
        title: 'Happy Diwali',
        description: 'Interactive Snapchat filter that adds a touch of celebration to your snaps. With this filter, users can control a virtual matchstick to light a trail of diyas, creating a festive atmosphere. The goal is to illuminate all the diyas and conclude the experience by sending out warm Diwali greetings.',
        tags: ['Lens Studio', 'Javascript', 'Illustrator'],
        icon: Assets.diwaliIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/2zleYOS8hl7oFHpVxh/giphy.gif'
    },
    {
        title: 'Balloon Splash',
        description: 'A lockdown-inspired creation, an snapchat lens to throw virtual colored water balloons at your friends, adjusting balloon size with your hand gestures. If balloon hits a dog, then the game ends, delivering a subtle reminder to avoid using colored water on animals during Holi for their safety.',
        tags: ['Lens Studio', 'Javascript'],
        icon: Assets.balloonIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNGticXFhaTJmeHJiOHVnYWZhZXR4NWJxeGdmc2QwemZ1c3QyZ2wyNyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/uLU5xTFP1Ru6A1aUWf/giphy-downsized-large.gif'
    },
    {
        title: 'Virus Stops Here',
        description: 'A meaningful lens promoting mask usage. Using machine learning, the lens detects if the user is without a mask, simulating virus entry with a green face indicating sickness. Once a mask is worn, the lens deflects viruses, restoring the face to normal. A creative reminder to prioritize safety and mask up for public health!',
        tags: ['Lens Studio', 'Javascript', 'Photoshop'],
        icon: Assets.virusIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/0hJZAgGGrwnMUkBM4A/giphy.gif'
    },
    {
        title: 'Save Our Planet',
        description: ' An engaging lens challenging users to address daily habits impacting our environment. Solve tasks like turning off lights, conserving water, and avoiding littering. Earn a badge to proudly wear when all tasks are completed, promoting awareness and encouraging positive environmental actions!',
        tags: ['Lens Studio', 'Javascript', 'Photoshop'],
        icon: Assets.planetIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/vtFtKZMo3qS0GBsP0p/giphy.gif'
    },
    {
        title: 'FIFA World Cup Prediction',
        description: 'A social filter for Snapchat and Instagram where users share predictions by tilting their heads towards favored teams, adjusting goal counts, engaging users in a fun and interactive way to showcase  predictions and join the excitement of the FIFA World Cup.',
        tags: ['Lens Studio', 'Javascript', 'Illustrator'],
        icon: Assets.worldcupIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/1vXH0NeZXfHg9D1WuJ/giphy.gif'
    },
    {
        title: 'Yomari Punhi',
        description: 'A festive playful filter available on Instagram and Tiktok in the spirit of Yomari Punhi, a Nepali celebration featuring a unique dumpling-shaped delicacy called Yomari. This filter lets users virtually enjoy Yomari.',
        tags: ['Lens Studio', 'Blender', 'Javascript', 'Photoshop'],
        icon: Assets.yomariIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/2WTuijAlq7pUxI2Nsf/giphy.gif'
    },
    {
        title: 'Pride Flag Dominos',
        description: 'A Snapchat lens using physics and Hand Tracking in Lens Studio providing an interactive way to celebrate diversity. Push one with your hand, and watch as they all fall, forming a vibrant Pride flag.',
        tags: ['Lens Studio', 'Javascript',],
        icon: Assets.prideIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/RK3jC0VPQ4SycsPn10/giphy.gif'
    },
    {
        title: 'Retro Apple',
        description: 'This lens uses ML to give your Apple device a cool vintage makeover by transforming your shiny modern apple logo into the iconic Apple logo.',
        tags: ['Lens Studio', 'Javascript'],
        icon: Assets.appleIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/RjDqI6MTknUISMuA2M/giphy.gif'
    },
    {
        title: 'Not Hotdog',
        description: 'Inspired by Silicon Valley, this lens uses ML to detect hot dogs. If it finds one, it simply says \'Hotdog\'. If not, it humorously declares \'Not Hot Dog\'.',
        tags: ['Lens Studio', 'Javascript', 'Photoshop'],
        icon: Assets.hotdogIcon,
        type: 'arvr',
        image: 'https://media.giphy.com/media/rStb2vhnGOnzwF5wds/giphy.gif'
    },
];



const hardware = [
    {
        title: 'Balloon Pop Bot',
        description: 'A manual bot with an oscillating niddle to pop balloons. The bot is controlled using a RF remote and powered by an Arduino Uno. The bot was made for a competition in an inter college tech fest.',
        type: 'hardware',
        tags: ['Arduino Uno'],
        image: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExejBoYXBndGRyb3I2ajZlcWJsNjhkMXY3anFtdm05dzUyNDdiMzEydyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/llElmXMn5UwPusIEF5/giphy.gif',
    },
    {
        title: 'Self Driving Bot',
        description: 'A prototype of a self driving bot. The bot uses a GPS sensor to navigate and a compass to orient itself. It uses Google Map\'s Direction API to get the steps to reach the destination and uses the GPS sensor to navigate. It also uses an ultrasonic sensor to detect obstacles and avoid them.',
        tags: ['Arduino Uno', 'GPS Module', 'Compass Module', 'Ultrasonic Module', 'Google Maps API'],
        type: 'hardware',
        image: Assets.selfDrivingImage,
    },
]

const opensource = [
    {
        title: 'django-allauth',
        description: 'Added Snapchat as an authentication Provider.',
        type: 'opensource',
        link: 'https://github.com/pennersr/django-allauth/commit/11110780cd6338fb18e36b2485b3643f3ea1e3a3',
    },
    {
        title: 'dj-rest-auth',
        description: 'Fixed password reset form url.',
        type: 'opensource',
        link: 'https://github.com/iMerica/dj-rest-auth/commit/2a82deb95e88b7ce98915e8f904cee8abfe1fed1',
    },
    {
        title: 'aframe',
        description: 'Documentation fix.',
        type: 'opensource',
        link: 'https://github.com/aframevr/aframe/commit/a8dca3adf9641e219b0238e91b6fe58844af01d2',

    },
    {
        title: 'ISEmojiView',
        description: 'Added keyboard settings parameters, fixed Delete button bug.',
        type: 'opensource',
        link: 'https://github.com/isaced/ISEmojiView/commit/1910e8fc3b53916d731112c8158e9d30db2396d8',

    }
];


const inthemedia = [
    {
        title: 'The Indian Express',
        description: 'My lens \'Happy Diwali\' was one of the 6 lenses featured during Diwali in India.',
        type: 'inthemedia',
        image: Assets.indianExpressImage,
        link: 'https://indianexpress.com/article/technology/social/snapchat-diwali-2020-ar-lenses-stickers-update-7049276/',
    },

    {
        title: 'News 18 - Bangla',
        description: 'I was one of the creators featured in News 18 Bangla for my festive lens during Durga Puja.',
        type: 'inthemedia',
        image: Assets.news18Image,
        link: 'https://bengali-news18-com.translate.goog/news/business/snapchat-adds-sparkle-and-colour-to-cultural-celebrations-with-new-festive-lenses-for-navratri-dussehra-and-durga-puja-ss-896143.html?_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp',
    },



];




const projects = [...appdev, ...webdev, ...arvr, ...hardware, ...opensource, ...inthemedia];

export default projects;