import './ProjectCard.css'
import { Assets } from '../Assets'

function ProjectCard({ project }) {

    const preventContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div className="project-card">
            <div className="description-card">
                <div className="description-card-content">
                    <div className="card-header">
                        <div className={`header-content ${project.title ? 'has-title' : 'no-title'}`}>
                            {project.icon && <img src={project.icon} alt={project.title} draggable="false" onContextMenu={preventContextMenu} />}
                            <h2>{project.title}</h2>
                        </div>
                        {project.link && (
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <img className="open-icon" src={Assets.openExtIcon} alt="Open" draggable="false" />
                            </a>
                        )}
                    </div>
                    {project.description && (
                        <p>{project.description}</p>
                    )}
                    {project.tags && project.tags.length > 0 && (
                        <div className="tag-container">
                            {project.tags.map((tag, index) => (
                                <span key={index} className="tag">{tag}</span>
                            ))}
                        </div>
                    )}</div>
            </div>
            {project.image && (
                <div className={project.longMedia != null ? "media-card-long" : "media-card"}>
                    <div className="overlay" />
                    <img src={project.image} alt={project.title} />
                </div>
            )}
            {/* {project.video && (
                <div className="video-card">
                    <img src={project.video} alt={project.title} />
                </div>
            )} */}
        </div>
    )
}

export default ProjectCard