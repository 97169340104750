import React, { useState, useEffect } from 'react';
import ProjectCard from './components/ProjectCard'
import './Projects.css'
import { useSelector } from 'react-redux';
import projects from './data';
import Masonry from 'react-masonry-css'

function Projects() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const activeTab = useSelector(state => state.activeTab);
    const selectedProjects = projects.filter(project => project.type === activeTab);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log(windowWidth);

    // const rearrangedProjects = (windowWidth >= 700 && selectedProjects.length !== 0) ?
    //     rearrangeData(selectedProjects, Math.ceil(selectedProjects.length / 2), 2) :
    //     selectedProjects;

    // console.log(rearrangedProjects);

    return (
        <Masonry
            breakpointCols={windowWidth >= 900 ? 2 : 1}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {/* <div className="projects-container"> */}
            {selectedProjects.map((project, index) => (
                <ProjectCard
                    key={index}
                    project={project}
                />
            ))}
            {/* </div> */}
        </Masonry>

    )
}

function rearrangeData(originalList, numRows, numColumns) {
    const rearrangedData = [];

    if (originalList.length === 3) {
        return [originalList[0], originalList[2], originalList[1]];
    }

    // if (originalList.length % 2 !== 0) {
    //     const oddItem = originalList[originalList.length - 1];
    //     numRows -= 1;
    //     originalList = originalList.slice(0, originalList.length - 1);

    //     for (let col = 0; col < numColumns; col++) {
    //         for (let row = 0; row < numRows; row++) {
    //             const index = row * numColumns + col;
    //             if (index < originalList.length) {
    //                 rearrangedData.push(originalList[index]);
    //             }
    //         }
    //     }

    //     rearrangedData.push(oddItem);
    //     return rearrangedData;
    // }

    for (let col = 0; col < numColumns; col++) {
        for (let row = 0; row < numRows; row++) {
            const index = row * numColumns + col;
            if (index < originalList.length) {
                rearrangedData.push(originalList[index]);
            }
        }
    }

    return rearrangedData;

}


export default Projects