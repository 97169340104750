import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import './NotFound.css'

function NotFound() {
    return (
        <div className="not-found">
            <FaExclamationTriangle size={120} />
            <h1>404</h1>
            <p>Page Not Found</p>
        </div>
    )
}

export default NotFound