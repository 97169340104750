import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Projects from './Projects';
import NotFound from './NotFound';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCh8Bf_QQG_MZuoKR7RxOSUp1XdxAsnGSE",
  authDomain: "portfolio-e7c36.firebaseapp.com",
  projectId: "portfolio-e7c36",
  storageBucket: "portfolio-e7c36.appspot.com",
  messagingSenderId: "177050240199",
  appId: "1:177050240199:web:2552ff8316e148bf786ae2",
  measurementId: "G-QV1DD68TN8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='/' element={<><Navbar /><Projects /></>} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;