export const Assets = {
    openExtIcon: '/assets/icons/open_ext.svg',
    devpostIcon: '/assets/icons/devpost.png',

    lolletIcon: '/assets/icons/lollet.png',
    dealsonbillsIcon: '/assets/icons/dealsonbills.jpg',
    countIcon: '/assets/icons/count.png',
    logsIcon: '/assets/icons/logs.png',
    kecIcon: '/assets/icons/kec.png',
    notinotesIcon: '/assets/icons/notinotes.png',
    evnepalIcon: '/assets/icons/evnepal.png',
    amoledIcon: '/assets/icons/amoled.jpg',
    opticareIcon: '/assets/icons/opticare.png',
    ldc325a1Icon: '/assets/icons/ldc325a1.png',
    nnzfsc: '/assets/icons/nnzfsc.png',
    mp4videoplayerIcon: '/assets/icons/mp4videoplayer.png',

    frenzieIcon: '/assets/icons/frenzieIcon.png',

    diwaliIcon: '/assets/icons/diwali.png',
    wordPlayIcon: '/assets/icons/wordplay.png',
    footballIcon: '/assets/icons/football.png',
    yomariIcon: '/assets/icons/yomari.png',
    balloonIcon: '/assets/icons/balloon.png',
    worldcupIcon: '/assets/icons/worldcup.png',
    guitarIcon: '/assets/icons/guitar.png',
    planetIcon: '/assets/icons/planet.png',
    hotdogIcon: '/assets/icons/hotdog.png',
    sliceIcon: '/assets/icons/slice.png',
    appleIcon: '/assets/icons/apple.png',
    virusIcon: '/assets/icons/virus.png',
    prideIcon: '/assets/icons/pride.png',

    testImage: '/assets/images/test.jpg',
    selfDrivingImage: '/assets/images/selfDriving.jpg',
    news18Image: '/assets/images/news18.png',
    indianExpressImage: '/assets/images/indianExpress.png',
};