import React, { useState, useEffect } from 'react'
import { FaLinkedin, FaGithub, FaArrowRight, } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import './Navbar.css'
import { Assets } from '../Assets';

function Navbar() {
    const [activeTab, setActiveTab] = useState('appdev');
    const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    const dispatch = useDispatch();

    const tabs = [
        { path: 'appdev', name: 'App Dev' },
        { path: 'webdev', name: 'Web Dev' },
        { path: 'arvr', name: 'AR/VR' },
        { path: 'hardware', name: 'Hardware' },
        { path: 'opensource', name: 'Open Source' },
        { path: 'inthemedia', name: 'In The Media' },
    ];

    const handleTabClick = (path) => {
        setActiveTab(path);
        dispatch({ type: 'SET_ACTIVE_TAB', payload: path });
    };

    useEffect(() => {
        const navbar = document.querySelector('.navbar');


        window.addEventListener('scroll', () => {
            // if (window.innerWidth < 700) {
            const offset = window.scrollY;
            navbar.style.backgroundColor = `rgba(255, 255, 255, ${Math.min(offset / 100, 1)})`;
            if (offset > 40) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
            // }
        });

        // listen scroll from scrollable container
        const scrollableContainer = document.querySelector('.scrollable-container');
        if (scrollableContainer.scrollLeft + scrollableContainer.clientWidth >= scrollableContainer.scrollWidth - 100) {
            setShowScrollIndicator(false);
        } else {
            setShowScrollIndicator(true);
        }
        scrollableContainer.addEventListener('scroll', () => {
            if (scrollableContainer.scrollLeft + scrollableContainer.clientWidth >= scrollableContainer.scrollWidth - 100) {
                setShowScrollIndicator(false);
            } else {
                setShowScrollIndicator(true);
            }
        });
    }, []);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <div className="navbar-header-container">
                        <h1>Karun Shrestha</h1>
                        <div className="socials-container">
                            <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-e7c36.appspot.com/o/Resume-Karun.pdf?alt=media&token=dacedf7e-91c4-47df-82b4-5d203a829eaf" target="_blank" rel="noopener noreferrer" class="download-cv-button">
                                <p>Download CV</p>
                            </a>
                            <div className="socials">
                                <a href="https://devpost.com/karunstha/" target="_blank" rel="noopener noreferrer">
                                    <div style={{
                                        backgroundImage: `url(${Assets.devpostIcon})`,
                                        backgroundSize: 'cover',
                                        height: '21px',
                                        width: '21px',
                                    }} />
                                </a>
                                <a href="https://www.linkedin.com/in/karunstha/" target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin />
                                </a>
                                <a href="https://github.com/karunstha" target="_blank" rel="noopener noreferrer">
                                    <FaGithub />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="scrollable-container">
                        <div className="tab-bar">
                            {tabs.map((tab, index) => (
                                <button key={index} onClick={() => handleTabClick(tab.path)} className={activeTab === tab.path ? 'active' : ''}>
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        {showScrollIndicator && <FaArrowRight className="scroll-indicator" />}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar